<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 37 37"
    :fill="color"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30.1505 6.20771V18.1575H27.9005V6.65771H19.6508V4.40771H28.3505C29.3446 4.40771 30.1505 5.2136 30.1505 6.20771Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.65039 11.1577C7.65039 9.91507 8.65775 8.90771 9.90039 8.90771H23.4004C24.643 8.90771 25.6504 9.91507 25.6504 11.1577V29.1577C25.6504 30.4004 24.643 31.4077 23.4004 31.4077H9.90039C8.65775 31.4077 7.65039 30.4004 7.65039 29.1577V11.1577ZM9.90039 11.1577H23.4004V29.1577H9.90039V11.1577Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#292929',
    },
    size: {
      type: Number,
      default: 37,
    },
  },
};
</script>
