/**
 * 将dataURL转换为blob
 * @param {*} dataURL
 */
export function dataURL2Blob(dataURL) {
  const arr = dataURL.split(',')
  const type = arr[0].match(/:(.*?);/)[1]
  const text = window.atob(arr[1]) // 将base64转换成文本
  let n = text.length
  const uint8Array = new Uint8Array(n)
  while (n--) {
    uint8Array[n] = text.charCodeAt(n)
  }
  return new Blob([uint8Array], { type })
}

/**
 * 将dataURL转换为file
 * @param {*} dataURL
 */
export function dataURL2File(dataURL) {
  const blob = dataURL2Blob(dataURL)
  return new File([blob], 'filename.' + blob.type.split('/')[1])
}

/**
 * 将blob转换为dataURL
 * @param {*} blob
 */
export function blob2DataURL(blob) {
  return new Promise((resolve) => {
    const fileReader = new FileReader()
    fileReader.onload = (e) => resolve(e.target.result)
    fileReader.readAsDataURL(blob)
  })
}

/**
 * 对图片优化
 * @param {*} url
 * @param {*} size
 * @returns
 */
export function formatImage(url, size, onlyWidth) {
  if (!url) return ''
  const arr = url.split('?')
  if (/\.(png|jpg|jpeg|gif)$/i.test(arr[0])) {
    const isGif = arr[0].endsWith('.gif')
    url += `${url.includes('?') ? '&' : '?'}x-oss-process=image`
    let devicePixelRatio = window.devicePixelRatio >= 2 ? 2 : 1
    if (isGif) {
      devicePixelRatio = 1
    }
    if (size) {
      size = Math.round(size * devicePixelRatio)
      url += `/resize,`
      if (onlyWidth) {
        url += `w_${size}`
      } else {
        url += `l_${size}`
      }
    }
    if (isGif) {
      return url
    }
    return `${url}/format,webp`
  }
  return url
}

export function replaceHost(obj, key) {
  if (obj[key]) {
    obj[key] = obj[key]
      .replaceAll(import.meta.env.VITE_OSS_URL, import.meta.env.VITE_CDN_URL)
      .replaceAll('https:', '')
  }
}
