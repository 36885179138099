<template>
  <svg
    :width="size"
    :height="(size * 31) / 30"
    viewBox="0 0 30 31"
    :fill="color"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M18.125 6.04199V4.16699H11.875V6.04199L18.125 6.04199Z" />
    <path d="M11.875 14.7188H13.75V21.5938H11.875V14.7188Z" />
    <path d="M16.25 14.7188H18.125V21.5938H16.25V14.7188Z" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5 8.46875H25V10.3438H23.1238C23.1246 10.3705 23.125 10.3974 23.125 10.4244V25.2631C23.125 26.6884 22.0057 27.8438 20.625 27.8438H9.375C7.99429 27.8438 6.875 26.6884 6.875 25.2631V10.4244C6.875 10.3974 6.8754 10.3705 6.8762 10.3438H5V8.46875ZM8.75 10.3438H21.25V25.9688H8.75V10.3438Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#292929',
    },
    size: {
      type: Number,
      default: 25,
    },
  },
};
</script>
