import { track } from '@/utils/track'

export default function install(Vue) {
  Vue.directive('track', {
    created(el, binding) {
      el.addEventListener(
        'click',
        () => {
          track(binding.value.key)
        },
        true
      )
    }
  })
}
