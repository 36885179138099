<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 19 19"
    :fill="color"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.50049 4.5H4.5V7.5H8.50049V4.5ZM4.5 3.5C3.94772 3.5 3.5 3.94772 3.5 4.5V7.5C3.5 8.05229 3.94772 8.5 4.5 8.5H8.50049C9.05277 8.5 9.50049 8.05229 9.50049 7.5V4.5C9.50049 3.94772 9.05277 3.5 8.50049 3.5H4.5ZM8.50049 10.5063H4.5V14.5H8.50049V10.5063ZM4.5 9.50635C3.94772 9.50635 3.5 9.95406 3.5 10.5063V14.5C3.5 15.0523 3.94772 15.5 4.5 15.5H8.50049C9.05277 15.5 9.50049 15.0523 9.50049 14.5V10.5063C9.50049 9.95406 9.05277 9.50635 8.50049 9.50635H4.5ZM11.5 4.5H14.5V14.5H11.5V4.5ZM10.5 4.5C10.5 3.94772 10.9477 3.5 11.5 3.5H14.5C15.0523 3.5 15.5 3.94772 15.5 4.5V14.5C15.5 15.0523 15.0523 15.5 14.5 15.5H11.5C10.9477 15.5 10.5 15.0523 10.5 14.5V4.5Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#292929',
    },
    size: {
      type: Number,
      default: 19,
    },
  },
};
</script>
