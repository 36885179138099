<template>
  <component
    class="ui-icon"
    :is="type"
    :color="color"
    :size="size"
    @click="$emit('click')"
  ></component>
</template>

<script>
import IconAdd from './icons/IconAdd.vue'
import IconClose from './icons/IconClose.vue'
import IconLink from './icons/IconLink.vue'
import IconShare from './icons/IconShare.vue'
import IconTemplate from './icons/IconTemplate.vue'
import IconEdit from './icons/IconEdit.vue'
import IconSave from './icons/IconSave.vue'
import IconCopy from './icons/IconCopy.vue'
import IconArrowUp from './icons/IconArrowUp.vue'
import IconArrowDown from './icons/IconArrowDown.vue'
import IconList from './icons/IconList.vue'
import IconText from './icons/IconText.vue'
import IconImage from './icons/IconImage.vue'
import IconReplace from './icons/IconReplace.vue'
import IconEarPhone from './icons/IconEarPhone.vue'
import IconEdit1 from './icons/IconEdit1.vue'
import IconAdd1 from './icons/IconAdd1.vue'
import IconRemove from './icons/IconRemove.vue'
import IconMap from './icons/IconMap.vue'
import IconAlignLeft from './icons/IconAlignLeft.vue'
import IconAlignCenter from './icons/IconAlignCenter.vue'
import IconAlignRight from './icons/IconAlignRight.vue'
import IconPicLeft from './icons/IconPicLeft.vue'
import IconPicCenter from './icons/IconPicCenter.vue'
import IconPicRight from './icons/IconPicRight.vue'
import IconTextBold from './icons/IconTextBold.vue'
import IconTextItalic from './icons/IconTextItalic.vue'
import IconTextUnderline from './icons/IconTextUnderline.vue'
import IconTextLineThrough from './icons/IconTextLineThrough.vue'

export default {
  name: 'UiIcon',
  emits: ['click'],
  components: {
    IconPicLeft,
    IconPicCenter,
    IconPicRight,
    IconAlignLeft,
    IconAlignCenter,
    IconAlignRight,
    IconTextBold,
    IconTextItalic,
    IconTextUnderline,
    IconTextLineThrough,
    IconMap,
    IconReplace,
    IconImage,
    IconText,
    IconList,
    IconCopy,
    IconArrowUp,
    IconArrowDown,
    IconAdd,
    IconAdd1,
    IconEdit,
    IconEdit1,
    IconClose,
    IconLink,
    IconShare,
    IconTemplate,
    IconSave,
    IconEarPhone,
    IconRemove
  },
  props: {
    type: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: '#292929'
    },
    size: {
      type: Number,
      default: 19
    }
  }
}
</script>

<style lang="scss" scoped>
.ui-icon {
  transition: 0.3s all;
  cursor: pointer;
}
</style>
