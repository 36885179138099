<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="5.14365"
      y="4.78513"
      width="19.1204"
      height="19.1204"
      rx="2.60733"
      :stroke="color"
      stroke-width="1.73822"
    />
    <rect x="9.48926" y="13.4766" width="10.4293" height="1.73822" :fill="color" />
    <rect
      x="15.573"
      y="9.13086"
      width="10.4293"
      height="1.73822"
      transform="rotate(90 15.573 9.13086)"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#292929',
    },
    size: {
      type: Number,
      default: 25,
    },
  },
};
</script>
