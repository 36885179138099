import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useEditorStore = defineStore('editor', () => {
  const showLoading = ref(false)
  const showRightPanel = ref(false)
  const isClickedSave = ref(false)
  const isReady = ref(false)
  const mdContent = ref('')
  const refreshTime = ref(0)
  const activeNode = ref(null)

  return {
    showLoading,
    showRightPanel,
    isClickedSave,
    refreshTime,
    isReady,
    mdContent,
    activeNode
  }
})
