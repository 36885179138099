<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 19 19"
    :fill="color"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.43164 5.75342C4.7423 5.75342 4.99414 5.50158 4.99414 5.19092C4.99414 4.88026 4.7423 4.62842 4.43164 4.62842C4.12098 4.62842 3.86914 4.88026 3.86914 5.19092C3.86914 5.50158 4.12098 5.75342 4.43164 5.75342ZM14.932 4.62842H6.30701V5.75342H14.932V4.62842ZM6.30701 9.09508H14.932V10.2201H6.30701V9.09508ZM4.43164 10.2201C4.7423 10.2201 4.99414 9.96824 4.99414 9.65758C4.99414 9.34692 4.7423 9.09508 4.43164 9.09508C4.12098 9.09508 3.86914 9.34692 3.86914 9.65758C3.86914 9.96824 4.12098 10.2201 4.43164 10.2201ZM6.30701 13.5617H14.932V14.6867H6.30701V13.5617ZM4.43164 14.6867C4.7423 14.6867 4.99414 14.4349 4.99414 14.1242C4.99414 13.8136 4.7423 13.5617 4.43164 13.5617C4.12098 13.5617 3.86914 13.8136 3.86914 14.1242C3.86914 14.4349 4.12098 14.6867 4.43164 14.6867Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#292929',
    },
    size: {
      type: Number,
      default: 19,
    },
  },
};
</script>
