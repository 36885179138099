<template>
  <svg
    :width="size"
    :height="(size * 21) / 20"
    viewBox="0 0 20 21"
    :fill="color"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.8719 4.87749C12.7547 4.76019 12.5956 4.6943 12.4298 4.69434C12.2639 4.69437 12.1049 4.76032 11.9877 4.87766L5.76537 11.1074C5.64833 11.2246 5.58258 11.3835 5.58258 11.5491V13.738C5.58258 14.0832 5.8624 14.363 6.20758 14.363H8.40761C8.57342 14.363 8.73243 14.2971 8.84965 14.1798L15.0683 7.95848C15.3122 7.71442 15.3122 7.31884 15.0683 7.07479L12.8719 4.87749ZM6.83258 11.8078L12.4301 6.20358L13.7425 7.51663L8.14865 13.113H6.83258V11.8078Z"
    />
    <path d="M16.4539 15.5183H4.37988V16.7683H16.4539V15.5183Z" />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#292929',
    },
    size: {
      type: Number,
      default: 20,
    },
  },
};
</script>
