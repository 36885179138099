<template>
  <button
    class="ui-button"
    :class="{ plain, disabled }"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'UiButton',
  emits: ['click'],
  props: {
    size: String,
    plain: Boolean,
    disabled: Boolean
  }
}
</script>

<style lang="scss" scoped>
.ui-button {
  height: 40px;
  font-size: 14px;
  line-height: 20px;
  padding: 0 12px;
  border-radius: 20px;
  color: #fff;
  background: #298eff;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  gap: 4px;
  border: 1px solid #298eff;
  &.plain {
    border: 1px solid #c9c9c9;
    background: #fff;
    color: #292929;
  }
  &.disabled {
    color: #b6b6b6;
    background: #eee;
    border: 1px solid #c9c9c9;
  }
}
</style>
