<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 21 21"
    :fill="color"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.6217 10.1864L15.8937 5.91434L15.0098 5.03046L10.7378 9.30251L6.46562 5.03035L5.58174 5.91423L9.8539 10.1864L5.58174 14.4585L6.46562 15.3424L10.7378 11.0703L15.0098 15.3423L15.8937 14.4584L11.6217 10.1864Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#292929',
    },
    size: {
      type: Number,
      default: 21,
    },
  },
};
</script>
