<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 19 19"
    :fill="color"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.8691 4.07379H3.93164C3.86803 4.07379 3.81646 4.12536 3.81646 4.18896V15.1265C3.81646 15.1901 3.86802 15.2416 3.93164 15.2416H14.8691C14.9328 15.2416 14.9843 15.1901 14.9843 15.1265V4.18896C14.9843 4.12534 14.9328 4.07379 14.8691 4.07379ZM3.93164 3.09521H14.8691C15.4732 3.09521 15.9629 3.5849 15.9629 4.18896V15.1265C15.9629 15.7305 15.4732 16.2202 14.8691 16.2202H3.93164C3.32758 16.2202 2.83789 15.7305 2.83789 15.1265V4.18896C2.83789 3.5849 3.32758 3.09521 3.93164 3.09521Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.05341 7.61853C6.05341 6.42012 7.02493 5.4486 8.22334 5.4486C9.42175 5.4486 10.3933 6.42012 10.3933 7.61853C10.3933 8.81694 9.42175 9.78846 8.22334 9.78846C7.02493 9.78846 6.05341 8.81694 6.05341 7.61853ZM8.22334 6.45513C7.58082 6.45513 7.05994 6.97601 7.05994 7.61853C7.05994 8.26105 7.58082 8.78193 8.22334 8.78193C8.86586 8.78193 9.38674 8.26105 9.38674 7.61853C9.38674 6.97601 8.86586 6.45513 8.22334 6.45513Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.332 9.91554C11.5256 9.73674 11.8243 9.73749 12.0169 9.91725L15.589 13.2506C15.7922 13.4402 15.8032 13.7587 15.6135 13.9619C15.4239 14.1651 15.1055 14.1761 14.9022 13.9865L11.6718 10.9719L8.76773 13.6549C8.57834 13.8298 8.28736 13.8334 8.09378 13.663L6.13175 11.9368L3.86224 13.684C3.642 13.8535 3.32601 13.8124 3.15645 13.5922C2.9869 13.372 3.02799 13.056 3.24823 12.8864L5.84609 10.8864C6.03681 10.7396 6.30482 10.7484 6.48553 10.9074L8.418 12.6076L11.332 9.91554Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#292929',
    },
    size: {
      type: Number,
      default: 19,
    },
  },
};
</script>
