<template>
  <ElConfigProvider :locale="zhCn">
    <RouterView />
    <!-- <SideBar></SideBar> -->
    <LoginDialog></LoginDialog>
  </ElConfigProvider>
</template>

<script>
import { mapStores } from 'pinia'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import { useCommonStore } from '@/stores/common'
import LoginDialog from '@/components/pc/LoginDialog.vue'
// import SideBar from '@/components/pc/SideBar.vue'
import { isIOS } from '@/utils/ua'

export default {
  components: {
    LoginDialog
    // SideBar
  },
  data() {
    return {
      zhCn
    }
  },
  computed: {
    ...mapStores(useCommonStore)
  },
  watch: {},
  async created() {},
  mounted() {
    // 因为用的是 vue 单页 spa， vue-router 切换的时候操作的都是浏览器的历史记录，ios 会把第一次刚进入时的 URL 作为真实 URL安卓会把当前 URL 作为真实 URL。导致后端在 getTicket 获得的 config 参数和微信 sdk 获取的 URL 参数是不一样的。
    localStorage.setItem('wx_code_url', location.href.split('#')[0])
    // 解决ios不支持按钮:active方法
    if (isIOS) {
      document.body.addEventListener('touchstart', function () {})
    }
  },
  methods: {}
}
</script>
<style lang="scss" scoped></style>
<style>
:root {
  /* 默认的底部安全区域至少有34px，太高了，所以重新设置一个值 */
  --safe-area-inset-bottom: max(calc(env(safe-area-inset-bottom) - 10px), 0px);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
#app {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
