const ua = window.navigator.userAgent
const is_ad_site = localStorage.getItem('is_ad_site')

export const isWeiXin = /micromessenger/i.test(ua)

export const isIOS = /iPhone|iPad|iPod/i.test(ua)

export const isAndroid = /Android/i.test(ua)

export const isMobile = isIOS || isAndroid || window.innerWidth < 768

export const isAdSite =
  /(bujuanppt|aiyemian)\.(cn|com)|(aiwangye|aiweiye|aiqingpian|dmxppt)\.cn|(dmxppt|jdppt)\.com/i.test(
    location.host
  ) || is_ad_site
