<template>
  <UiDialog class="global" v-model="commonStore.showLoginBox" :width="isAdSite ? '300px' : '384px'">
    <div class="login" v-if="!isAdSite">
      <div @click="commonStore.showLoginBox = false" class="close">
        <UiIcon type="IconClose" :size="30" />
      </div>
      <img src="/logo.svg" class="logo-left" alt="" />
      <div class="logo-text">秒作</div>
      <div class="wechat">
        <img :src="imgPrefix + 'wechat_20240801.svg'" alt="" />
        微信扫码登录 作品永久保存
      </div>
      <div class="code">
        <img :src="img" alt="" />
      </div>
      <!-- <div class="desc">
        登录即表示同意
        <span class="agreement" @click="openAgreement">《用户协议》、</span>
        <span class="agreement" @click="openAgreement">《隐私协议》</span>
      </div> -->
    </div>
    <div class="login ad" v-else>
      <img src="/logo.svg" class="logo-left" alt="" />
      <div class="logo-text">H5制作工具</div>
      <ElForm @submit.prevent="onLogin">
        <ElInput v-model="account" size="large" placeholder="账号"></ElInput>
        <ElInput v-model="password" size="large" show-password placeholder="密码"></ElInput>
        <ElButton type="primary" size="large" @click="onLogin">登录</ElButton>
      </ElForm>
      <span class="support">客服联系方式：<a :href="'tel:' + 15576263994">15576263994</a></span>
    </div>
  </UiDialog>
</template>

<script>
import { mapStores } from 'pinia'
import { useCommonStore } from '@/stores/common'
import { useEditorStore } from '@/stores/editor'
import { useUserStore } from '@/stores/user'
import UserModel from '@/models/user'
import { track } from '@/utils/track'
import { isAdSite } from '@/utils/ua'

export default {
  data() {
    return {
      isAdSite,
      img: '',
      timer: null,
      account: '',
      password: ''
    }
  },
  computed: {
    ...mapStores(useCommonStore, useEditorStore, useUserStore)
  },
  watch: {
    'commonStore.showLoginBox'(val) {
      if (val) {
        track('aih5_pc_componentView_login')
        if (!isAdSite) {
          this.load()
        }
      } else {
        this.onClose()
      }
    }
  },
  created() {},
  unmounted() {
    this.onClose()
  },
  methods: {
    async load() {
      const { uid, url } = await UserModel.getLoginQrcode()
      this.img = url
      this.timer = setInterval(async () => {
        const token = await UserModel.wechatCheck(uid)
        if (token) {
          // localStorage.setItem('token', JSON.stringify(token));
          this.userStore.setSession({ access_token: token })
          // clearInterval(this.timer);
          // if (token.is_new_user) {
          //   window.uetq = window.uetq || [];
          //   window.uetq.push('event', 'register', {});
          // }

          this.editorStore.isClickedSave = false
          this.commonStore.showLoginBox = false
          if (!this.$route.meta.ignoreLogin) {
            location.reload()
          } else {
            this.userStore.refreshUser()
          }
        }
      }, 1000)
    },
    onClose() {
      clearInterval(this.timer)
      // 首页未登录时点立即制作，又不登录。刷新时，会弹出登录框。避免这种情况
      sessionStorage.removeItem('keywords')
    },
    openAgreement() {
      // window.open(import.meta.env.VITE_MAIN_URL + '/agreement')
    },
    async onLogin() {
      const data = await UserModel.login({ account: this.account, pw: this.password })
      this.userStore.setSession({ access_token: data })
      this.commonStore.showLoginBox = false
      if (!this.$route.meta.ignoreLogin) {
        location.reload()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
  height: 480px - 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &.ad {
    height: 300px;
    gap: 10px;
    .el-form {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 100%;
    }
    .support {
      line-height: 40px;
      a {
        color: #298eff;
      }
    }
  }
}
.close {
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
  border-radius: 3px;
  transition: all 0.3s;
  &:hover {
    background: #f1f1f1;
  }
}
.logo-left {
  height: 55px;
}
.logo-text {
  color: #232922;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 7px 0 8px 0;
}
.wechat {
  display: flex;
  align-items: center;
  color: #232922;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 112.5% */
  > img {
    height: 18px;
    margin-right: 4px;
  }
}
.code {
  width: 224px;
  height: 224px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #dddedc;
  margin: 24px 0 24px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  > img {
    max-width: 98%;
    max-height: 98%;
  }
}
.desc {
  font-size: 14px;
}
.desc .agreement {
  color: var(--blue-blue-500, #298eff);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
}
</style>
