<template>
  <component
    :is="tag"
    :contenteditable="contenteditable"
    @input="update"
    @blur="update"
    @paste="onPaste"
    @keypress="onKeypress"
    ref="element"
  >
  </component>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue'

defineOptions({
  name: 'UiContenteditable'
})

const props = defineProps({
  tag: String,
  contenteditable: {
    type: [Boolean, String],
    default: true
  },
  modelValue: String,
  noHtml: {
    type: Boolean,
    default: true
  },
  noNl: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['returned', 'update:modelValue'])

const element = ref()

function currentContent() {
  return props.noHtml ? element.value?.innerText : element.value?.innerHTML
}

function updateContent(newContent) {
  if (element.value) {
    if (props.noHtml) {
      element.value.innerText = newContent
    } else {
      element.value.innerHTML = newContent
    }
  }
}

function update() {
  emit('update:modelValue', currentContent() ?? '')
}

function replaceAll(str, search, replacement) {
  return str.split(search).join(replacement)
}

function onPaste(event) {
  event.preventDefault()
  let text = (event.originalEvent || event).clipboardData.getData('text/plain')
  if (props.noNl) {
    text = replaceAll(text, '\r\n', ' ')
    text = replaceAll(text, '\n', ' ')
    text = replaceAll(text, '\r', ' ')
  }
  window.document.execCommand('insertText', false, text)
}
function onKeypress(event) {
  if (event.key == 'Enter' && props.noNl) {
    event.preventDefault()
    emit('returned', currentContent())
  }
}

onMounted(() => {
  updateContent(props.modelValue ?? '')
})

watch(
  () => props.modelValue,
  (newVal) => {
    if (newVal != currentContent()) {
      updateContent(newVal ?? '')
    }
  }
)

watch(
  () => props.noHtml,
  () => {
    updateContent(props.modelValue ?? '')
  }
)

watch(
  () => props.tag,
  () => {
    updateContent(props.modelValue ?? '')
  },
  { flush: 'post' }
)
</script>
