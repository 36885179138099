<template>
  <svg
    :width="size"
    :height="(size * 29) / 28"
    viewBox="0 0 28 29"
    :fill="color"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.5376 11.4933L21.5376 6.43555H19.7994L19.7994 9.39391C18.335 7.80167 16.233 6.8022 13.8972 6.8022C9.46973 6.8022 5.88058 10.3914 5.88058 14.8188C5.88058 19.2462 9.46973 22.8354 13.8972 22.8354C16.1721 22.8354 18.2269 21.8866 19.685 20.3656L18.4302 19.1628C17.2864 20.356 15.6791 21.0972 13.8972 21.0972C10.4297 21.0972 7.6188 18.2862 7.6188 14.8188C7.6188 11.3513 10.4297 8.54043 13.8972 8.54043C15.7516 8.54043 17.4187 9.34391 18.5689 10.6242H15.6107V12.3624H20.6685C21.1485 12.3624 21.5376 11.9733 21.5376 11.4933Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#292929',
    },
    size: {
      type: Number,
      default: 28,
    },
  },
};
</script>
