import axios from './axios'

export { axios }

export default {
  post(url, data) {
    return axios.post(url, data)
  },
  get(url, params) {
    return axios.get(url, { params })
  },
  put(url, data) {
    return axios.put(url, data)
  },
  delete(url, params) {
    return axios.delete(url, { params })
  }
}
