import UiIcon from './UiIcon.vue'
import UiButton from './UiButton.vue'
import UiDrawer from './UiDrawer.vue'
import UiDialog from './UiDialog.vue'
import UiContenteditable from './UiContenteditable.vue'

export default function install(Vue) {
  Vue.component(UiIcon.name, UiIcon)
  Vue.component(UiButton.name, UiButton)
  Vue.component(UiDrawer.name, UiDrawer)
  Vue.component(UiDialog.name, UiDialog)
  Vue.component(UiContenteditable.name, UiContenteditable)
}
