<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 28 28"
    :fill="color"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.0446 10.6597C18.0446 8.42594 16.2338 6.61512 14 6.61512C11.7662 6.61512 9.9554 8.42594 9.9554 10.6597V17.3402C9.9554 19.574 11.7662 21.3848 14 21.3848C14.5334 21.3848 14.9658 21.8172 14.9658 22.3505C14.9658 22.8839 14.5334 23.3163 14 23.3163C10.8724 23.3163 8.30593 20.9137 8.04561 17.8535C6.3107 17.4299 5.02309 15.8653 5.02309 14C5.02309 12.1346 6.3107 10.57 8.04561 10.1464C8.30592 7.08621 10.8724 4.68359 14 4.68359C17.1276 4.68359 19.6941 7.08622 19.9544 10.1465C21.6892 10.57 22.9768 12.1346 22.9768 14C22.9768 16.1397 21.2825 17.8838 19.1622 17.9636C19.1128 17.9715 19.062 17.9755 19.0104 17.9755C18.477 17.9755 18.0446 17.5431 18.0446 17.0098V10.6597Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#292929',
    },
    size: {
      type: Number,
      default: 28,
    },
  },
};
</script>
