<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 19 19"
    :fill="color"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.06836 4.17334C4.06836 3.92093 4.27298 3.71631 4.52539 3.71631H14.2754C14.5278 3.71631 14.7324 3.92093 14.7324 4.17334V5.67645C14.7324 5.92887 14.5278 6.13349 14.2754 6.13349C14.023 6.13349 13.8184 5.92887 13.8184 5.67645V4.63037H4.98242V5.67645C4.98242 5.92887 4.7778 6.13349 4.52539 6.13349C4.27298 6.13349 4.06836 5.92887 4.06836 5.67645V4.17334Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.40039 3.71631C9.6528 3.71631 9.85742 3.92093 9.85742 4.17334V15.1421C9.85742 15.3945 9.6528 15.5991 9.40039 15.5991C9.14798 15.5991 8.94336 15.3945 8.94336 15.1421V4.17334C8.94336 3.92093 9.14798 3.71631 9.40039 3.71631Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.50586 15.1421C6.50586 14.8897 6.71048 14.6851 6.96289 14.6851H11.8379C12.0903 14.6851 12.2949 14.8897 12.2949 15.1421C12.2949 15.3945 12.0903 15.5991 11.8379 15.5991H6.96289C6.71048 15.5991 6.50586 15.3945 6.50586 15.1421Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#292929',
    },
    size: {
      type: Number,
      default: 19,
    },
  },
};
</script>
