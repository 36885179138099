<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 37 37"
    :fill="color"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.9004 13.001L7.4983 24.4031L5.90731 22.8121L17.3094 11.41C18.1881 10.5313 19.6127 10.5313 20.4914 11.41L31.8935 22.8121L30.3025 24.4031L18.9004 13.001Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#292929',
    },
    size: {
      type: Number,
      default: 37,
    },
  },
};
</script>
