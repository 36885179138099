<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 28 28"
    :fill="color"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.69727 4.375C6.73077 4.375 5.94727 5.1585 5.94727 6.125V21.875C5.94727 22.8415 6.73078 23.625 7.69727 23.625H11.1973V21.875H7.69727V6.125H19.9473V11.375H21.6973V6.125C21.6973 5.15851 20.9138 4.375 19.9473 4.375H7.69727Z"
    />
    <path d="M17.3223 9.625H9.44727V11.375H17.3223V9.625Z" />
    <path
      d="M22.0528 15.5987L15.729 23.4104L14.3689 23.5929V22.3093L20.6926 14.4976L22.0528 15.5987Z"
    />
    <path d="M14.6973 13.125H9.44727V14.875H14.6973V13.125Z" />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#292929',
    },
    size: {
      type: Number,
      default: 28,
    },
  },
};
</script>
