<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 19 19"
    :fill="color"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9.5 7.4375V6.3125L6.125 6.3125L6.125 7.4375L9.5 7.4375Z" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.625 3.875C4.00368 3.875 3.5 4.37868 3.5 5V14C3.5 14.6213 4.00368 15.125 4.625 15.125H14.375C14.9963 15.125 15.5 14.6213 15.5 14V7.78311C15.5 7.44569 15.3485 7.12608 15.0874 6.91241L11.6858 4.1293C11.4848 3.96485 11.2331 3.875 10.9734 3.875H4.625ZM14.375 14V7.78311L10.9734 5H4.625L4.625 14H5.9375V12.125C5.9375 11.6072 6.35723 11.1875 6.875 11.1875H12.5C13.0178 11.1875 13.4375 11.6072 13.4375 12.125V14H14.375ZM12.3125 14H7.0625V12.3125H12.3125V14Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#292929',
    },
    size: {
      type: Number,
      default: 19,
    },
  },
};
</script>
