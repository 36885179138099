<template>
  <div class="ui-drawer" :class="{ active: modelValue }">
    <div class="ui-drawer-wrap" :style="{ height }">
      <div class="ui-drawer-header" v-if="title">
        <span>{{ title }}</span>
        <UiIcon type="IconClose" :size="24" @click="onClose"></UiIcon>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiDrawer',
  emits: ['update:modelValue'],
  props: {
    modelValue: Boolean,
    height: String,
    title: String
  },
  mounted() {
    // document.body.appendChild(this.$el);
  },
  methods: {
    onClose() {
      this.$emit('update:modelValue', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.ui-drawer {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  pointer-events: none;
  transition: 0.3s all;
  z-index: 100;
  .ui-drawer-wrap {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    transition: 0.3s all;
    background: #fff;
    border-radius: 16px;
    transform: translateY(100%);
    .ui-drawer-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 50px;
      padding: 0 16px;
      font-size: 16px;
      font-weight: 500;
    }
  }
  &.active {
    opacity: 1;
    pointer-events: unset;
    .ui-drawer-wrap {
      transform: translateY(0);
    }
  }
}
</style>
