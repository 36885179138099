<template>
  <transition name="fade">
    <div class="ui-dialog" v-if="modelValue">
      <div class="ui-dialog-wrap" :style="{ width }">
        <div class="ui-dialog-header" v-if="title">
          <span class="title">{{ title }}</span>
          <div class="close" @click="onClose">
            <UiIcon type="IconClose" :size="30"></UiIcon>
          </div>
        </div>
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'UiDialog',
  emits: ['update:modelValue'],
  props: {
    modelValue: Boolean,
    title: String,
    width: {
      type: String,
      default: '500px'
    }
  },
  data() {
    return {}
  },
  created() {},
  mounted() {
    setTimeout(() => {
      document.body.appendChild(this.$el)
    })
  },
  methods: {
    onClose() {
      this.$emit('update:modelValue', false)
    }
  },
  computed: {}
}
</script>

<style lang="scss" scoped>
.ui-dialog {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000;
  .ui-dialog-wrap {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    background: #fff;
    border-radius: 16px;
    padding: 18px 14px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .ui-dialog-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        color: #000;
        font-size: 20px;
        font-weight: 500;
      }
      .close {
        cursor: pointer;
        border-radius: 3px;
        transition: all 0.3s;
        &:hover {
          background: #f1f1f1;
        }
      }
    }
  }
}
</style>
