import { createApp } from 'vue'
import { createPinia } from 'pinia'
import TlbsMap from 'tlbs-map-vue'
// import * as Sentry from '@sentry/vue'
import directive from '@/directives'
import ui from '@/components/ui'
import router from '@/router'

import '@/styles/reset.scss'
import '@/styles/element-plus.scss'
import '@/styles/vant.scss'
import App from '@/App.vue'

const app = createApp(App)

app.config.globalProperties.imgPrefix = import.meta.env.VITE_IMAGE_PREFIX_URL.replaceAll(
  'https:',
  ''
)

// if (['test', 'prod'].includes(import.meta.env.MODE)) {
//   Sentry.init({
//     app,
//     dsn: 'https://71e3c6a60b613e351a02bcf122ebd642@o4507775591579648.ingest.us.sentry.io/4507775595642880',
//     integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
//     // Performance Monitoring
//     tracesSampleRate: 1.0, //  Capture 100% of the transactions
//     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//     // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//   })
// }

app.use(createPinia())
app.use(router)
app.use(TlbsMap)
app.use(directive)
app.use(ui)

app.mount('#app')

console.log('mode:', import.meta.env.MODE)
console.log('version:', __APP_VERSION__)
console.log('hash:', __APP_HASH__)
