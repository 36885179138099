import WorkModel from '@/models/work'
import { getUserDeviceId } from '@/utils/device.js'

export function track(eventType) {
  const options = {
    uid: getUserDeviceId(),
    eventType
  }
  WorkModel.dau(options)
}
