import http from '@/utils/http'
import axios from '@/utils/axios'
import { getUserDeviceId } from '@/utils/device.js'

export default {
  /**
   * 判断是否是流量版用户
   * @param {*} options
   * @returns
   */
  // async getAgentUser(params) {
  //   return await http.get(`/api/agent/getAgentUser`, params)
  // },
  /**
   * 账号密码登录
   * @param {*} params
   * @returns
   */
  async login(params) {
    return await http.post(`/api/agent/login`, params)
  },

  /**
   * 获取用户信息
   * @returns
   */
  async getUserInfo() {
    return await axios.get(`${import.meta.env.VITE_MAIN_URL}/api/user/info`, {
      ignoreMessage: true
    })
  },

  /**
   * 获取微信二维码和票据号
   * @returns
   */
  getLoginQrcode() {
    return http.get(`${import.meta.env.VITE_MAIN_URL}/api/wx/getQrUrl`)
  },

  /**
   * 需要轮询，检测扫码关注是否成功
   * @param title
   * @returns taskID
   */
  wechatCheck(uid) {
    return http.get(`${import.meta.env.VITE_MAIN_URL}/api/wx/qrLogin?uid=${uid}`)
  }

  /**
   * 埋点
   * @returns
   */
  // actionStatistic(data) {
  //   return http.post(`${import.meta.env.VITE_MAIN_URL}/api/action/statistic`, data)
  // }
}
