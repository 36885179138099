import { ref, reactive } from 'vue'
import { defineStore } from 'pinia'

export const useCommonStore = defineStore('common', () => {
  const showLoginBox = ref(false)
  const wxApiSignature = reactive({})

  return {
    showLoginBox,
    wxApiSignature
  }
})
