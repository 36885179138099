<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 19 19"
    :fill="color"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.9602 5.16484L12.3125 2.51709L11.517 3.31258L13.1821 4.97767C10.892 4.97036 9.17444 5.28777 7.99957 6.16216C6.60793 7.19786 6.12502 8.89098 6.12502 11.1875V11.75H7.25002V11.1875C7.25002 9.00602 7.71428 7.77684 8.67123 7.06465C9.55757 6.405 10.9821 6.09211 13.2265 6.10308L11.517 7.81258L12.3125 8.60808L14.9602 5.96033C15.1799 5.74066 15.1799 5.38451 14.9602 5.16484ZM3.875 10.625V14.5702C3.875 14.8809 4.12684 15.1327 4.4375 15.1327H14.5609C14.8715 15.1327 15.1234 14.8809 15.1234 14.5702V10.625H16.2484V14.5702C16.2484 15.5022 15.4928 16.2577 14.5609 16.2577H4.4375C3.50552 16.2577 2.75 15.5022 2.75 14.5702V10.625H3.875Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#292929',
    },
    size: {
      type: Number,
      default: 19,
    },
  },
};
</script>
