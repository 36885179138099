import axios from 'axios'
import { useCommonStore } from '@/stores/common'
import { useUserStore } from '@/stores/user'
import { isWeiXin } from '@/utils/ua'
import router from '@/router'

axios.defaults.baseURL = import.meta.env.VITE_API_URL
// axios.defaults.withCredentials = true
// axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.validateStatus = (status) => {
  return status < 500
}
axios.interceptors.request.use(
  (config) => {
    if (!config.headers.Authorization) {
      const userStore = useUserStore()
      const Authorization = userStore.Authorization
      if (Authorization) {
        config.headers.Authorization = Authorization
      }
    }
    return config
  },
  (err) => {
    return Promise.reject(err)
  }
)

axios.interceptors.response.use(
  function (response) {
    const commonStore = useCommonStore()
    const userStore = useUserStore()
    const { data, status, config } = response
    // map的是腾讯地图接口，status为0才正常

    if (
      status === 200 &&
      (data.status === 1 || (config.url.includes('map') && data.status === 0))
    ) {
      return data.data
    } else if ([401, 403].includes(status) || [40000, 40100].includes(data.status)) {
      // 40000 未登录, 40100 已在别处登录
      userStore.clearSession()

      // 避免退出登录时，在路由拦截那里调用info接口后，path还未切换就进来的情况
      setTimeout(() => {
        if (!isWeiXin && !router.currentRoute.value.meta.ignoreLogin) {
          commonStore.showLoginBox = true
        }
      })
    }
    if (!config.ignoreMessage) {
      ElMessage.error(data.message || '未知错误')
    }
    return Promise.reject(new Error(data.message || '未知错误'))
  },
  function (err) {
    if (!err.config.ignoreMessage) {
      ElMessage.error('服务异常，请稍后重试')
    }
    return Promise.reject(err)
  }
)

export default axios
