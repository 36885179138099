<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 25 25"
    :fill="color"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.8867 11.3984V4.14844H11.3867V11.3984H4.13672V12.8984H11.3867V20.1484H12.8867V12.8984H20.1367V11.3984H12.8867Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#292929',
    },
    size: {
      type: Number,
      default: 25,
    },
  },
};
</script>
