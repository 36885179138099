<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 37 37"
    :fill="color"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.9004 24.3144L30.3025 12.9124L31.8935 14.5033L20.4914 25.9054C19.6127 26.7841 18.1881 26.7841 17.3094 25.9054L5.9073 14.5033L7.49829 12.9124L18.9004 24.3144Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#292929',
    },
    size: {
      type: Number,
      default: 37,
    },
  },
};
</script>
