<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 1024 1024"
    :fill="color"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M798 160H366c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h181.2l-156 544H229c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h432c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8H474.4l156-544H798c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8z"
    ></path>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#292929'
    },
    size: {
      type: Number,
      default: 25
    }
  }
}
</script>
