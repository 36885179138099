import { v4 } from 'uuid'

/**
 * 获取用户唯一凭证， 如果没有会生成一个
 * @returns
 */
export function getUserDeviceId() {
  const USER_DEVICE_ID = 'user_device_id'
  let userDeviceId = localStorage.getItem(USER_DEVICE_ID)
  if (userDeviceId) {
    return userDeviceId
  } else {
    userDeviceId = v4().replace(/-/g, '')
    localStorage.setItem(USER_DEVICE_ID, userDeviceId)
    return userDeviceId
  }
}
