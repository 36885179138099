import { isWeiXin, isIOS, isAdSite } from '@/utils/ua'
import WeixinModel from '@/models/weixin'
import { formatImage } from '@/utils/file'
import { useCommonStore } from '@/stores/common'

/**
 * ios中微信浏览器首次加载页面初始化title后，就不再监听document.title的change事件，所以需要用iframe刷新一下
 * @param {*} title
 */
export function refreshTitle(title) {
  const i = document.createElement('iframe')
  i.src = import.meta.env.BASE_URL + 'logo.svg'
  i.style.display = 'none'
  i.onload = function () {
    setTimeout(function () {
      i.remove()
    })
  }
  document.title = title
  document.body.appendChild(i)
}

export function setWxShareInfo(shareInfo = {}) {
  const wx = window.wx
  if (wx) {
    const {
      title = (isAdSite ? '' : '秒作 | ') + '免费制作H5网页',
      description:
        desc = '10秒自动生成H5网页，适用于邀请函、通知公告、营销推广、公众号图文等场景！',
      thumb = import.meta.env.VITE_IMAGE_PREFIX_URL + 'share-cover_20240803.png',
      url = ''
    } = shareInfo
    const link = url || `${location.origin + import.meta.env.BASE_URL}`
    const imgUrl = formatImage(thumb, 200)
    console.log('shareInfo', { title, desc, link, imgUrl })
    wx.updateAppMessageShareData({ title, desc, link, imgUrl })
    wx.updateTimelineShareData({ title, link, imgUrl })
  }
}

export async function getJsApiSignature(shareInfo) {
  if (!isWeiXin) return
  const commonStore = useCommonStore()
  const url = isIOS ? localStorage.getItem('wx_code_url') : location.href
  let data = null
  if (commonStore.wxApiSignature[url]) {
    data = commonStore.wxApiSignature[url]
  } else {
    data = await WeixinModel.getJsApiSignature({
      url: isIOS ? localStorage.getItem('wx_code_url') : location.href
    })
    commonStore.wxApiSignature[url] = data
  }
  const wx = window.wx
  if (wx) {
    wx.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      ...data,
      jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData', 'openLocation'] // 必填，需要使用的JS接口列表
    })
    wx.ready(() => {
      setWxShareInfo(shareInfo ?? {})
    })
    wx.error((err) => {
      console.error(err)
    })
  }
}
