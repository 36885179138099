<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 17 17"
    :fill="color"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.09764 3.41259C6.225 2.53994 4.80831 2.57154 3.9336 3.44623C3.0589 4.32092 3.0273 5.73764 3.89995 6.61028L5.93887 8.6492C6.11949 8.82982 6.41233 8.82982 6.59295 8.64921C6.77356 8.46859 6.77356 8.17575 6.59295 7.99513L4.55403 5.9562C4.05904 5.46122 4.05757 4.6304 4.58767 4.10031C5.11779 3.5702 5.94858 3.57167 6.44356 4.06666L8.79204 6.41512L8.79732 6.42032C9.28714 6.91593 9.28675 7.74278 8.75849 8.27104C8.57787 8.45166 8.57787 8.74449 8.75849 8.92511C8.93911 9.10573 9.23195 9.10573 9.41256 8.92511C10.2873 8.0504 10.3189 6.63371 9.44623 5.76106L9.44002 5.75496L7.09764 3.41259Z"
    />
    <path
      d="M11.1581 7.46462C10.9774 7.284 10.6846 7.284 10.504 7.46462C10.3234 7.64524 10.3234 7.93807 10.504 8.11869L12.5429 10.1576C13.0379 10.6526 13.0394 11.4834 12.5093 12.0135C11.9792 12.5436 11.1484 12.5422 10.6534 12.0472L8.3049 9.6987C8.29936 9.69317 8.29373 9.6878 8.28799 9.68261C8.28261 9.67664 8.27705 9.67078 8.2713 9.66504C7.77632 9.17006 7.77485 8.33927 8.30496 7.80915C8.48558 7.62853 8.48558 7.3357 8.30496 7.15508C8.12434 6.97446 7.8315 6.97446 7.65089 7.15508C6.77618 8.02978 6.74459 9.44648 7.61723 10.3191C7.62276 10.3246 7.6284 10.33 7.63414 10.3352C7.63952 10.3412 7.64508 10.347 7.65082 10.3528L9.9993 12.7012C10.872 13.5739 12.2886 13.5423 13.1634 12.6676C14.038 11.7929 14.0697 10.3762 13.197 9.50355L11.1581 7.46462Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#292929',
    },
    size: {
      type: Number,
      default: 17,
    },
  },
};
</script>
